import { useEffect, useRef, useState } from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import posthog from "posthog-js";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { format } from "date-fns";
import useAuth from "../../utils/useAuth";
import { useAppDispatch } from "../../store";
import { updateOrganizationKeyValue, uploadLogo } from "../../store/organization";
import apiClient from "../../utils/axiosInstance";
import SpinnerLoading from "../SpinnerLoading";
import AIModelsSection from "../AIModelsSection";
import Modal from "../Modal";
import NewPasswordModal from "../NewpasswordModal";
import { updateUserDetails } from "../../store/authSlice";
import { useAppSelector } from "../../store";

function AccountSetting({ userDetailsState, org }) {
    const { handleLogOut } = useAuth();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [newPasswordModal, setNewPasswordModal] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingImage, setIsEditingImage] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [error, setError] = useState(null);
    const [newUserName, setNewUserName] = useState(userDetailsState?.name);
    const [newUserImage, setNewUserImage] = useState(userDetailsState.userPictureUrl);
    const fileInputRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleLogoutFun = () => {
        handleLogOut();
    };

    const confirmDeleteAccount = async (payload) => {
        try {
            setIsLoading(true);

            const response = await apiClient().post("/account", {selfDelete: true, idToDelete: payload.idTodelete, changeRoleMemeberId: payload.changeRoleMemeberId});

            if (response.data && response.status === 200) {
                posthog.reset(true);
                // dispatch(handleLogOut());
                navigate("/");
            }
        } catch (error) {
            toast.error("Error while deleting account: " + error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            setNewUserImage(e.target.files[0])
        }
    }


    const handleSave = async () => {
        try {
            if (newUserName === userDetailsState?.name) {
                setIsEditing(false);
                return;
            }
            const formData = new FormData();
            formData.append('name', newUserName);
            const response = await apiClient().put("/update-profile", formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            dispatch(updateUserDetails({ name: response.data.name }));
        } catch (error) {
            console.error("Error updating User name:", error);
        } finally {
            setIsEditing(false);
        }
    };

    const handleSaveImage = async () => {
        try {
            if (!newUserImage || !(newUserImage instanceof File)) {
                toast.error("Please select a valid image before uploading.");
                return;
            }
            const formData = new FormData();
            formData.append('photo', newUserImage);

            if (!formData.has('photo')) {
                toast.error("No image found in FormData.");
                return;
            } else {

                setIsLoading(true);
                const response = await apiClient().put("/update-profile", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });
                dispatch(updateUserDetails({ userPictureUrl: response.data.photo }));
                setIsLoading(false);
                setIsModalOpen(false);
            }
        } catch (error) {
            console.error("Error updating User Image:", error);
            toast.error("Error updating image, please try again.");
            setIsLoading(false);
        } finally {
            setIsEditingImage(false);
            setIsLoading(false);
        }
    }



    const handleFileUpload = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        setIsUploading(true);
        setError(null);

        const formData = new FormData();
        formData.append('logo', file);

        try {
            const config = { headers: { "Content-Type": "multipart/form-data" } };
            const response = await apiClient().post('/logo', formData, config);
            dispatch(uploadLogo(response.data));
        } catch (err) {
            setError('Failed to upload logo. Please try again.');
        } finally {
            setIsUploading(false);
        }
    };

    const handleCancle = () => {
        setIsEditing(false);
    }




    return (
        <>
            {(isLoading || isUploading) && <SpinnerLoading isLoading={true} />}
            {(userDetailsState && org) &&
                <div className="bg-white rounded-lg w-full m-auto">
                    <div className="account-details">
                        <div className="rounded-xl">
                            <div className="flex items-center justify-between border-b-2 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">Profile Picture</p>
                                <div className="flex w-[40%]">
                                    <div className="relative">
                                        {userDetailsState?.userPictureUrl ? (
                                            <img
                                                src={userDetailsState.userPictureUrl}
                                                alt="Profile"
                                                className="rounded-full h-12 w-12 object-cover hover:cursor-pointer"
                                            // onClick={() => setIsModalOpen(true)} 
                                            />
                                        ) : (
                                            <div className="text-white text-2xl rounded-full h-12 w-12 flex items-center justify-center cursor-pointer" style={{ backgroundColor: org?.secondaryColor }} onClick={() => setIsModalOpen(true)}>
                                                {userDetailsState?.name?.charAt(0).toUpperCase()}
                                            </div>
                                        )}

                                    </div>
                                    {isLoading ? (
                                        <div className="flex justify-center items-center py-4">
                                            <SpinnerLoading isLoading={true} />
                                        </div>
                                    ) : (
                                        isModalOpen && (
                                            <modal
                                                onClose={() => setIsModalOpen(false)}
                                                className="fixed pl-50 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center "
                                            >
                                                <div className="p-4 md:p-8 rounded-lg bg-white md:w-[600px] w-[350px]">
                                                    <div className="flex items-center justify-between pb-10">
                                                        <h1 className="text-2xl text-center font-bold ">Upload Image</h1>
                                                        <Icon
                                                            icon="fa:times"
                                                            className="cursor-pointer text-md"
                                                            width="1.2em"
                                                            height="1.2em"
                                                            onClick={() => setIsModalOpen(false)}
                                                        />
                                                    </div>
                                                    <input
                                                        type="file"
                                                        accept="image/*"
                                                        id="userPictureUrl"
                                                        name="userPictureUrl"
                                                        onChange={handleImageChange}
                                                        ref={fileInputRef}
                                                        className="my-2 bg-gray-200 w-full p-2 m-2 rounded-lg mb-5"
                                                    />
                                                    <div className="flex items-center justify-around pt-2">
                                                        <button
                                                            onClick={handleSaveImage}
                                                            className="mt-2 px-4 py-2 bg-green-400 hover:bg-green-500 text-white rounded"
                                                        >
                                                            Upload
                                                        </button>
                                                        <button
                                                            onClick={() => setIsModalOpen(false)}
                                                            className="mt-2 px-4 py-2 bg-red-400 hover:bg-red-500 text-white rounded"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </div>
                                            </modal>
                                        )
                                    )}
                                </div>
                                <button className="text-lg w-[20%]" style={{ color: org?.primaryColor }} onClick={() => setIsModalOpen(true)} >Update</button>
                            </div>
                            <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">UserName</p>
                                <div className="flex w-[40%]">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={newUserName}
                                                onChange={(e) => setNewUserName(e.target.value)}
                                                className="text-base font-normal text-textPrimary mr-2 py-2 border border-borderColor rounded-full"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base font-normal  w-[250px] py-2 rounded-full   text-textPrimary mr-2">
                                                {userDetailsState?.name}
                                            </p>
                                        </>
                                    )}
                                </div>
                                {isEditing && (
                                    <button
                                        className="text-lg w-[20%]  text-slate-400"
                                        onClick={handleCancle}
                                    >
                                        Cancel
                                    </button>
                                )}
                                <button
                                    className="text-lg w-[20%]"
                                    style={{ color: org?.primaryColor }}
                                    onClick={isEditing ? handleSave : handleEdit}
                                >
                                    {isEditing ? 'Save' : 'Update'}
                                </button>
                            </div>

                            {/* <div className="flex items-center justify-between border-b-2 mt-5 border-borderColor pb-5">
                                <p className="text-lg font-normal text-secondary">UserName</p>
                                <div className="flex">
                                    {isEditing ? (
                                        <>
                                            <input
                                                type="text"
                                                value={newUserName}
                                                onChange={(e) => setNewUserName(e.target.value)}
                                                className="text-base font-normal text-textPrimary mr-2 p-1 border rounded"
                                            />
                                            <Icon icon="pajamas:false-positive" className="cursor-pointer" width="1.2em" height="1.2em" onClick={handleSave} />
                                        </>
                                    ) : (
                                        <>
                                            <p className="text-base font-normal text-textPrimary mr-2">
                                                {userDetailsState?.name}
                                            </p>
                                            <Icon icon="fa:edit" className="cursor-pointer text-green-700" width="1.2em" height="1.2em" onClick={handleEdit} />
                                        </>
                                    )}
                                </div>
                                <button className="text-lg" style={{ color: org?.primaryColor }}>Update</button>
                            </div> */}
                            <div className="flex items-center justify-between border-b-2 border-borderColor mt-5 pb-5">
                                <p className="text-lg font-normal text-secondary w-[40%]">Email</p>
                                <div className="flex w-[40%]">
                                    <p className="text-base font-normal text-textPrimary">
                                        {userDetailsState?.email}
                                    </p>
                                </div>
                                <div className="w-[20%]"></div>
                            </div>
                        </div>
                    </div>
                    <div className="account-system mt-8 pr-10">
                        <div className=" rounded-xl flex items-center justify-end gap-10">

                            <div className="flex items-center  justify-between  border-borderColor">
                                <div className="flex">
                                    <button
                                        onClick={() => setShowModal(true)}
                                        className="text-md font-normal  text-red-500 py-2 px-5 border-2 rounded-full  w-full transition-colors duration-300"
                                    >
                                        Delete Account
                                    </button>
                                </div>
                            </div>
                            <div className="flex items-center justify-between  border-borderColor">
                                <div className="flex">
                                    <button
                                        onClick={handleLogoutFun}
                                        className="flex items-center py-2 px-5  border-2 text-center text-md font-medium rounded-full text-textPrimary"
                                    >
                                        Logout
                                    </button>
                                </div>
                            </div>

                            <div className="flex items-center justify-between ">
                                <div className="flex">
                                    <button
                                        style={{ backgroundColor: org?.primaryColor }}
                                        onClick={() => setNewPasswordModal(!newPasswordModal)}
                                        className=" text-md font-normal border-2 text-white py-2 px-5 rounded-full w-full transition-colors duration-300"
                                    >
                                        Update Password
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>}
            {showModal && <DeleteAccount setShowModal={setShowModal} confirmDeleteAccount={confirmDeleteAccount} />}
            {/* <Modal
                showModal={showModal}
                setShowModal={setShowModal}
                confirmAction={confirmDeleteAccount}
                label={"Delete Account"}
                description={"Are you sure you want to delete your account?"}
                confirmText={"Confirm"}
                cancelText={"Cancel"}
            /> */}

            <NewPasswordModal
                newPasswordModal={newPasswordModal}
                setNewPasswordModal={setNewPasswordModal}
            />
        </>
    );
}

export default AccountSetting;


const DeleteAccount = ({ setShowModal, confirmDeleteAccount }) => {

    const [stage, setStage] = useState(0);
    const [memberList, setMemeberList] = useState([]);
    const [member, setMember] = useState("");
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [confirm, setConfirm] = useState(false)

    const { userDetailsState, organizationState } = useAppSelector((state) => ({
        userDetailsState: state.auth.userDetails,
        organizationState: state.organization.organization,
    }));

    useEffect(() => {
        if (userDetailsState) {
            if (userDetailsState.role === "super admin") {
                if (organizationState[0]?.members?.length > 1) {
                    setStage(2);
                    setMemeberList(organizationState[0]?.members.filter((member) => member.userId !== userDetailsState.userId));
                    console.log(memberList)
                } else {
                    setStage(1);
                }
            } else {
                setStage(3);
            }
        }
    }, [userDetailsState, organizationState])

    return <>
        <div className="fixed pl-64 top-0 left-0 w-full h-full bg-gray-900 bg-opacity-50 z-50 flex justify-center items-center">
            <div className="py-4 px-8 rounded-lg w-[350px] md:w-[600px] bg-white z-51">
                <div className="flex items-center justify-between my-3">
                    <h2 className="text-2xl font-bold">Delete Account</h2>
                    <button
                        onClick={() => setShowModal(false)}
                        className="text-gray-500 hover:text-gray-700"
                    >
                        <Icon icon="ic:baseline-close" width="1.2em" height="1.2em" />
                    </button>
                </div>
                {stage === 3 && <>
                    <div className="flex items-center justify-between my-5">
                        <p className="text-lg text-center font-normal text-secondary w-full">Are you sure you want to delete your account?</p>
                    </div>
                    <div className="flex items-center justify-center my-3">
                        <button
                            onClick={() => setShowModal(false)}
                            className="text-md font-normal py-2 px-5 border-2 rounded-md  transition-colors duration-300 mx-4"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={() => confirmDeleteAccount({idToDelete: userDetailsState.userId})}
                            className="text-md font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4"
                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                        >
                            Delete Account
                        </button>
                    </div>
                </>}
                {stage === 2 && <>
                    <div className="flex flex-col items-center justify-between my-5">
                        <p className="text-lg text-center font-normal text-secondary w-full">You are only superAdmin of this organization. To Delete this account you have to select user from below list to make them superAdmin.</p>
                        <select
                            value={member}
                            onChange={(e) => setMember(e.target.value)}
                            className="w-80 my-4 border border-gray-300 rounded-md py-2 px-3 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={""} style={{ display: "none" }}>
                                Select a member
                            </option>
                            {memberList.length === 0 && <option value="none" disabled>There are no more members to chat with</option>}
                            {memberList?.map((obj) => (
                                <option key={obj.userId} value={obj.userId} className="py-2 px-3">
                                    {obj.name}
                                </option>
                            ))}
                        </select>

                        {showConfirmation && <p className="text-lg text-center font-normal text-red-600 w-full">You are about to change the superAdmin of this organization and delete this account!</p>}

                    </div>
                    <div className="flex items-center justify-center my-3">
                        <button
                            onClick={() => setShowModal(false)}
                            className="text-md font-normal py-2 px-5 border-2 rounded-md  transition-colors duration-300 mx-4"
                        >
                            Cancel
                        </button>
                        {showConfirmation ? <button
                            onClick={() => confirmDeleteAccount({idToDelete: userDetailsState.userId, changeRoleMemeberId: member})}
                            className="text-md font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4"
                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                        >
                            Confirm Delete
                        </button> : <button
                            onClick={() => member !== "" && setShowConfirmation(true)}
                            className="text-md font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4"
                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                        >
                            Delete Account
                        </button>}
                    </div>
                </>}
                {stage === 1 && <>
                    <div className="flex items-center justify-between my-5">
                        <p className="text-lg text-center font-normal text-secondary w-full">You are only Super Admin User in this organization, You can't delete this organization!</p>
                    </div>
                    <div className="flex items-center justify-center my-3">
                        <button
                            onClick={() => setShowModal(false)}
                            className="text-md font-normal  text-white py-2 px-5 border-2 rounded-md transition-colors duration-300 mx-4"
                            style={{ backgroundColor: organizationState[0]?.primaryColor }}
                        >
                            Go Back
                        </button>
                    </div>
                </>}

            </div>
        </div>
    </>
};