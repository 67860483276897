import { Icon } from "@iconify/react/dist/iconify.js";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import apiClient from "../../utils/axiosInstance";
import SpinnerLoading from "../SpinnerLoading";
import { useAppDispatch, useAppSelector } from "../../store";
import { addMember, getTeams } from "../../store/teamSlice";
import TeamModal from "../Team/TeamModal";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { getOrganization } from "../../store/organization";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email selection is required"),
});

function InviteModal({ setInviteModule, inviteModule, selectedTeam }) {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [teamModal, setTeamModal] = useState(false);
  const [initialTeam, setInitialTeam] = useState(null);
  const [availableMembers, setAvailableMembers] = useState([]);
  const { teamState, status, organizationState, organizationStatus } =
    useAppSelector((state) => ({
      teamState: state.teams.teams,
      status: state.teams.status,
      organizationState: state.organization.organization,
      organizationStatus: state.organization.organizationStatus,
    }));
  useEffect(() => {
    if (
      inviteModule &&
      (!organizationState || organizationState.length === 0)
    ) {
      dispatch(getOrganization());
    }
  }, [inviteModule, dispatch, organizationState]);

  useEffect(() => {
    const fetchTeams = async () => {
      try {
        dispatch(getTeams());
      } catch (error) {
        console.log(error);
      }
    };
    if (inviteModule && !teamState?.length > 0) {
      fetchTeams();
    }
  }, [inviteModule, dispatch, teamState]);

  useEffect(() => {
    if (teamState.length > 0) {
      const teamToUse = selectedTeam || teamState[0].teamId;
      setInitialTeam(teamToUse);
      updateAvailableMembers(teamToUse);
    }
  }, [selectedTeam, teamState, organizationState]);

  const closeModal = () => {
    setInviteModule(false);
  };

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      setIsLoading(true);
      const response = await apiClient().post(`/invite-user-to-team`, {
        email: values.email,
        teamId: values.teamId,
      });
      dispatch(addMember(response.data.member));
      updateAvailableMembers(values.teamId);
      resetForm();
      setInviteModule(false);
      toast.success("Invitation sent successfully");
    } catch (error) {
      toast.error("Error sending invitation");
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };

  const createTeam = () => {
    closeModal();
    setTeamModal(true);
  };

  const updateAvailableMembers = (teamId) => {
    const selectedTeam = teamState.find((team) => team.teamId === teamId);
    if (selectedTeam && organizationState && organizationState[0]?.members) {
      const teamMemberEmails = selectedTeam.members.map(
        (member) => member.email
      );
      const filteredMembers = organizationState[0].members.filter(
        (member) =>
          !teamMemberEmails.includes(member.email) &&
          member.isAccepted == true
      );
      setAvailableMembers(filteredMembers);
    }
  };

  return (
    <>
      {isLoading && <SpinnerLoading isLoading={isLoading} />}
      {inviteModule && (
        <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
          <div className="bg-white rounded-lg overflow-hidden shadow-xl w-[350px] md:w-[600px]">
            <div className="p-6">
              {organizationStatus === "loading" || status === "loading" ? (
                <div className="animate-pulse">
                  <div className="flex items-center justify-between mb-6">
                    <div className="h-8 bg-gray-300 rounded w-1/2"></div>
                    <div className="h-8 w-8 bg-gray-300 rounded-full"></div>
                  </div>
                  <div className="space-y-4">
                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                    <div className="h-10 bg-gray-300 rounded"></div>
                    <div className="h-4 bg-gray-300 rounded w-3/4"></div>
                    <div className="h-10 bg-gray-300 rounded"></div>
                  </div>
                  <div className="mt-6 h-10 bg-gray-300 rounded"></div>
                </div>
              ) : teamState?.length === 0 ? (
                <div>
                  <div className="flex items-center justify-between mb-6">
                    <h2 className="text-2xl font-bold">No Spaces Found</h2>
                    <button
                      onClick={closeModal}
                      className="p-2 hover:bg-gray-100 rounded-full transition duration-200"
                    >
                      <Icon
                        icon="iconamoon:close"
                        width="1.5em"
                        height="1.5em"
                      />
                    </button>
                  </div>
                  <p className="text-lg mb-8">
                    To invite your colleague, start by creating a team. This
                    ensures an organized structure for effective collaboration.
                  </p>
                  <button
                    onClick={createTeam}
                    className="w-full text-white font-medium py-2 px-4 rounded-md hover:bg-opacity-90 transition duration-200"
                    style={{ backgroundColor: organizationState[0]?.primaryColor }}
                  >
                    Create Space
                  </button>
                </div>
              ) : (
                <Formik
                  initialValues={{
                    email: "",
                    teamId: selectedTeam.teamId || selectedTeam,
                  }}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {({ isSubmitting, setFieldValue, values }) => (
                    <Form className="bg-white">
                      <div className="flex items-center justify-end mb-2">
                        <p className="text-lg font-semibold w-full">
                          {selectedTeam?.teamName}
                        </p>
                        <button
                          onClick={closeModal}
                          className="p-2 hover:bg-gray-100 rounded-full transition duration-200"
                        >
                          <Icon
                            icon="iconamoon:close"
                            width="1.5em"
                            height="1.5em"
                          />
                        </button>
                      </div>

                      <div className="mb-4">
                       
                        <Field
                          as="select"
                          id="email"
                          name="email"
                          className="border border-gray-300 rounded-md p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                        >
                          <option value="">Select an email</option>
                          {availableMembers.map((member) => (
                            <option
                              key={member.email}
                              value={member.email}
                            >
                              {member.email}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="email"
                          component="div"
                          className="text-red-500 text-sm mt-1 text-start"
                        />
                      </div>
                      <button
                        type="submit"
                        disabled={isSubmitting}
                        className="w-full  text-white font-medium py-3 px-4 rounded-md hover:bg-opacity-90 transition duration-200"
                        style={{
                          backgroundColor: organizationState[0]?.primaryColor,
                        }}
                      >
                        {isSubmitting ? "Inviting..." : "Invite Member"}
                      </button>
                    </Form>
                  )}
                </Formik>
              )}
            </div>
          </div>
        </div>
      )}
      <TeamModal
        teamModal={teamModal}
        setTeamModal={setTeamModal}
        initialTeam={initialTeam}
        setInitialTeam={setInitialTeam}
      />
    </>
  );
}

export default InviteModal;
